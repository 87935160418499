import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { HolidayGroupModel } from './holiday-groups.model';

export interface HolidayGroupsState extends EntityState<HolidayGroupModel> {
  adding: boolean;
  isLoading: boolean;
  updating: { [key: string]: boolean };
}

export const adapter: EntityAdapter<HolidayGroupModel> = createEntityAdapter<HolidayGroupModel>({});

export const initialState: HolidayGroupsState = adapter.getInitialState({
  adding: false,
  isLoading: false,
  updating: {},
});
