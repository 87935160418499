/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DepartmentModel } from '../department/department.model';
import { HolidayGroupModel } from './holiday-groups.model';

export const HolidayGroupsActions = createActionGroup({
  source: 'Holiday Groups',
  events: {
    'Add and Link Departments Request': props<{ holidayGroup: Partial<HolidayGroupModel>; departmentIds?: string[] }>(),
    'Add and Link Departments Success': props<{ holidayGroup: HolidayGroupModel }>(),
    'Add and Link Departments Failed': emptyProps(),

    'Load All Request': emptyProps(),
    'Load All Success': props<{ holidayGroups: HolidayGroupModel[] }>(),
    'Load All Failed': emptyProps(),

    'Delete Request': props<{ groupId: string }>(),
    'Delete Success': props<{ groupId: string }>(),
    'Delete Failed': props<{ groupId: string }>(),

    'Edit and Link Department Request': props<{
      holidayGroup: Partial<HolidayGroupModel>;
      departments?: Partial<DepartmentModel>[];
    }>(),
    'Edit and Link Department Success': props<{
      holidayGroup: HolidayGroupModel;
      departments?: Partial<DepartmentModel>[];
    }>(),
    'Edit and Link Department Failed': props<{ holidayGroupId: string }>(),
  },
});
